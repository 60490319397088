import fetch from "auth/FetchInterceptor";

const MeetServices = {};

MeetServices.getMeetings = function (params) {
    return fetch({
        url: "/meetings",
        method: "GET",
        params,
    });
}

MeetServices.getMeeting = function (id) {
    return fetch({
        url: `/meeting/${id}`,
        method: "GET",
    });
}

MeetServices.createMeeting = function (data) {
    return fetch({
        url: "/meeting",
        method: "POST",
        data,
    });
}

MeetServices.updateMeeting = function (id, data) {
    return fetch({
        url: `/meeting/${id}`,
        method: "PUT",
        data,
    });
}

MeetServices.deleteMeeting = function (id) {
    return fetch({
        url: `/meeting/${id}`,
        method: "DELETE",
    });
}

MeetServices.joinMeeting = function (data) {
    return fetch({
        url: "/meeting/join",
        method: "POST",
        data,
    });
}

MeetServices.static = function () {
    return fetch({
        url: "/meetings/static",
        method: "GET",
    });
}

MeetServices.endMeeting = function (data) {
    return fetch({
        url: "/meeting/end",
        method: "POST",
        data,
    });
}

MeetServices.generateText = function (data) {
    return fetch({
        url: "/meeting/generate",
        method: "POST",
        data,
    });
}

MeetServices.saveDiscussion = function (data, meetId) {
    return fetch({
        url: `/meeting/discussion/${meetId}`,
        method: "POST",
        data,
    });
}


MeetServices.chatBot = function (data,) {
    return fetch({
        url: `/meeting/chatbot`,
        method: "POST",
        data,
    });
}


export default MeetServices;
