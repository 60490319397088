import { UpOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import IntlMessage from 'components/util-components/IntlMessage';
import { useState, useEffect } from 'react';
import MeetServices from 'services/MeetServices';

const setLocale = (isLocaleOn, localeKey) =>
    isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const ChatBot = () => {
    const [show, setShow] = useState(false);
    const [inputValue, setInputValue] = useState(''); // State to manage input value
    const [messages, setMessages] = useState([]); // State to store messages

    // Load messages from sessionStorage when the component mounts
    useEffect(() => {
        const savedMessages = sessionStorage.getItem('chatMessages');
        if (savedMessages) {
            setMessages(JSON.parse(savedMessages));
        }
    }, []);

    // Save messages to sessionStorage whenever the messages state changes
    useEffect(() => {
        sessionStorage.setItem('chatMessages', JSON.stringify(messages));
    }, [messages]);

    const handleIconClick = () => {
        setShow(!show);
    };

    const handleCloseClick = () => {
        setShow(false);
    };

    const handleSendClick = () => {
        if (inputValue.trim()) {
            // Add the user's message to the chat
            const newUserMessage = { text: inputValue, isUser: true };
            setMessages([...messages, newUserMessage]);
            setInputValue(''); // Clear the input after sending

            // Prepare the request payload
            const requestPayload = {
                message: inputValue // Format the payload as required
            };

            // Send the request to the chatbot API
            MeetServices.chatBot(requestPayload).then((res) => {
                // Add the bot's response to the chat
                const newBotMessage = { text: res.data, isUser: false };
                setMessages((prevMessages) => [...prevMessages, newBotMessage]);
            }).catch((error) => {
                console.error("Error fetching chatbot response:", error);
                // Optionally, add an error message to the chat
                const errorMessage = { text: "Sorry, something went wrong. Please try again.", isUser: false };
                setMessages((prevMessages) => [...prevMessages, errorMessage]);
            });
        }
    };
    
    return (
        <div style={{ position: 'fixed', bottom: '0rem', right: '1rem' }}>
            {!show && (
                <div
                    className='justify-content-between'
                    style={{
                        backgroundColor: 'rgb(164, 97, 216)',
                        padding: '10px',
                        marginBottom: '2px',
                        borderRadius: '8px',
                        color: 'white',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        width: '20rem',
                    }}
                    onClick={handleIconClick}
                >
                    <span>Assiste</span>
                    <UpOutlined />
                </div>
            )}
            {show && (
                <div
                    style={{
                        width: '20rem',
                        height: '500px',
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'rgb(164, 97, 216)',
                            color: 'white',
                            padding: '10px',
                            borderTopLeftRadius: '8px',
                            borderTopRightRadius: '8px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <span>Chat</span>
                        <div>
                            <CloseOutlined style={{ cursor: 'pointer' }} onClick={handleCloseClick} />
                        </div>
                    </div>

                    {/* Chat Messages Area */}
                    <div
                        style={{
                            flex: 1,
                            padding: '10px',
                            overflowY: 'auto',
                            borderBottom: '1px solid #ddd',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {/* Render messages */}
                        {messages.map((message, index) => (
                            <div
                                key={index}
                                style={{
                                    alignSelf: message.isUser ? 'flex-end' : 'flex-start',
                                    backgroundColor: message.isUser ? 'rgb(164, 97, 216)' : '#f1f1f1',
                                    color: message.isUser ? 'white' : 'black',
                                    padding: '8px 12px',
                                    borderRadius: '12px',
                                    marginBottom: '8px',
                                    maxWidth: '70%',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {message.text}
                            </div>
                        ))}
                    </div>

                    {/* Input and Send Button */}
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            borderTop: '1px solid #ddd',
                        }}
                    >
                        <input
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Type a message..."
                            style={{
                                flex: 1,
                                padding: '8px',
                                borderRadius: '4px',
                                border: '1px solid #ddd',
                                marginRight: '10px',
                                outline: 'none',
                            }}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSendClick();
                                }
                            }}
                        />
                        <SendOutlined
                            style={{ cursor: 'pointer', fontSize: '18px', color: 'rgb(164, 97, 216)' }}
                            onClick={handleSendClick}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatBot;